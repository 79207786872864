export const chainNetwork = [
// 主网
{
  chainId: 1,
    chainName: 'ETH Mainnet',
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    rpcUrls: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: ['https://etherscan.io/'],
    aliasName: 'ETH',
    nickName: 'ETH',
    mintContract: '0xB38D06CBa39E830fB3ED099d2DA0Dad97a7B6d76'
},
//测试网
{
  chainId: 97,
  chainName: 'BSC Mainnet',
  name: 'BNB',
  symbol: 'BNB',
  decimals: 18,
  rpcUrls: ['https://bsc-testnet.publicnode.com'],
  blockExplorerUrls: ['https://bscscan.com/'],
  aliasName: 'BNB',
  nickName: 'BNB',
  mintContract: '0x1866C327C7285224c8d187B7Ca416d23B42c2487'
}
]